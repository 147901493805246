/**
 * Main file to declare available plans and helpers
 */

import { formatCurrency } from '@pkgs/shared-client/helpers/format';
import BillingCycle from '@pkgs/shared/enums/BillingCycle';
import SubscriptionTier from '@pkgs/shared/enums/SubscriptionTier';
import SubscriptionType from '@pkgs/shared/enums/SubscriptionType';

export type ClientOrServerConfigWithPayment = {
	payment: {
		tiers: {
			[tier in ValueOf<typeof SubscriptionTier>]: {
				[billingCycle in ValueOf<typeof BillingCycle>]: {
					stripePlanID: string;
					paypalPlanID?: string;
				};
			};
		};
		oldTiers: {
			[tier in ValueOf<typeof SubscriptionTier>]: {
				[billingCycle in ValueOf<typeof BillingCycle>]: Array<string>;
			};
		};
		enterprisePlans: Array<{
			stripePlanID: string;
			billingCycle: ValueOf<typeof BillingCycle>;
			price: number;
		}>;
	};
};

const TIER_TO_TYPE_MAP = {
	[SubscriptionTier.PRO]: SubscriptionType.INDIVIDUAL,
	[SubscriptionTier.SITE_MAKER]: SubscriptionType.INDIVIDUAL,
	[SubscriptionTier.TEAM]: SubscriptionType.TEAM,
	[SubscriptionTier.ENTERPRISE]: SubscriptionType.TEAM,
} as const;
// } satisfies Record<ValueOf<typeof SubscriptionTier>, ValueOf<typeof SubscriptionType>>;

const AVAILABLE_PLANS_SPEC: Readonly<
	Array<{
		tier: ValueOf<typeof SubscriptionTier>;
		billingCycle: ValueOf<typeof BillingCycle>;
		enabled: boolean;
		highlight: boolean;
		price: number;
		includedUnits: number;
		priceExtraUnit?: number;
	}>
> = [
	{
		tier: SubscriptionTier.PRO,
		billingCycle: BillingCycle.YEARLY,
		enabled: true,
		highlight: false,
		price: 95.88, // 7.99 * 12
		includedUnits: 1,
	},
	{
		tier: SubscriptionTier.PRO,
		billingCycle: BillingCycle.MONTHLY,
		enabled: true,
		highlight: false,
		price: 9.99,
		includedUnits: 1,
	},
	{
		tier: SubscriptionTier.SITE_MAKER,
		billingCycle: BillingCycle.YEARLY,
		enabled: true,
		highlight: true,
		price: 179.88, // 14.99 * 12
		includedUnits: 1,
	},
	{
		tier: SubscriptionTier.SITE_MAKER,
		billingCycle: BillingCycle.MONTHLY,
		enabled: true,
		highlight: true,
		price: 18.99,
		includedUnits: 1,
	},
	{
		tier: SubscriptionTier.TEAM,
		billingCycle: BillingCycle.YEARLY,
		enabled: true,
		highlight: false,
		price: 1188, // 99 * 12
		includedUnits: 10,
		priceExtraUnit: 119.88, // 9.99 * 12
	},
	{
		tier: SubscriptionTier.TEAM,
		billingCycle: BillingCycle.MONTHLY,
		enabled: true,
		highlight: false,
		price: 149,
		includedUnits: 10,
		priceExtraUnit: 14.99,
	},
	{
		tier: SubscriptionTier.ENTERPRISE,
		billingCycle: BillingCycle.YEARLY,
		enabled: false, // Don't allow user to change to this plan
		highlight: false,
		price: 0,
		includedUnits: 0,
	},
	{
		tier: SubscriptionTier.ENTERPRISE,
		billingCycle: BillingCycle.MONTHLY,
		enabled: false, // Don't allow user to change to this plan
		highlight: false,
		price: 0,
		includedUnits: 0,
	},
] as const;

export type PlanSpec = (typeof AVAILABLE_PLANS_SPEC)[number];

export type Plan = PlanSpec & {
	stripePlanID: string;
	paypalPlanID?: string;
};

function getPlanSpecFromSubscriptionTierAndBillingCycle(
	tier: ValueOf<typeof SubscriptionTier>,
	billingCycle: ValueOf<typeof BillingCycle>,
): PlanSpec {
	const plan = AVAILABLE_PLANS_SPEC.find(
		(p) => p.tier === tier && p.billingCycle === billingCycle,
	);

	if (plan) {
		return plan;
	}

	// If not found, return first plan
	return AVAILABLE_PLANS_SPEC[0];
}

function getPlanFromPlanSpec(config: ClientOrServerConfigWithPayment, planSpec: PlanSpec): Plan {
	const planIDs = config.payment.tiers[planSpec.tier][planSpec.billingCycle];

	if (!planIDs) {
		throw new Error(`Plan IDs not found for ${planSpec.tier}:${planSpec.billingCycle}`);
	}

	const plan: Plan = {
		...planSpec,
		stripePlanID: planIDs.stripePlanID,
	};

	if (planIDs.paypalPlanID) {
		plan.paypalPlanID = planIDs.paypalPlanID;
	}

	return plan;
}

export function getPlanFromSubscriptionTierAndBillingCycle(
	config: ClientOrServerConfigWithPayment,
	tier: ValueOf<typeof SubscriptionTier>,
	billingCycle: ValueOf<typeof BillingCycle>,
): Plan {
	const planSpec = getPlanSpecFromSubscriptionTierAndBillingCycle(tier, billingCycle);

	return getPlanFromPlanSpec(config, planSpec);
}
const _getMonthlyPlan = (plan: Plan) => {
	if (plan.billingCycle === BillingCycle.MONTHLY) {
		return undefined;
	}

	return AVAILABLE_PLANS_SPEC.find(
		(p) => p.tier === plan.tier && p.billingCycle === BillingCycle.MONTHLY,
	);
};
// The discount percentage in relation to the monthly plan
export function getPlanDiscountPercentage(plan: Plan): number | undefined {
	const monthlyPlan = _getMonthlyPlan(plan);
	if (!monthlyPlan) {
		return undefined;
	}
	return Math.floor(((monthlyPlan.price - getPlanMonthlyPrice(plan)) / monthlyPlan.price) * 100);
}

export function getPlanDiscount(plan: Plan): number | undefined {
	const monthlyPlan = _getMonthlyPlan(plan);
	if (!monthlyPlan) {
		return undefined;
	}
	return Math.floor((monthlyPlan.price - getPlanMonthlyPrice(plan)) * 12 * 100) / 100;
}

// Monthly price equivalent for yearly plans
export function getPlanMonthlyPrice(planSpec: PlanSpec): number {
	let price = 0;

	if (planSpec.billingCycle === BillingCycle.MONTHLY) {
		price = planSpec.price;
	} else {
		price = planSpec.price / 12;
	}

	return Math.round(price * 100) / 100;
}

export function getPlanMonthlyExtraUnitPrice(planSpec: PlanSpec): number | undefined {
	let price = 0;

	if ('priceExtraUnit' in planSpec && typeof planSpec.priceExtraUnit === 'number') {
		if (planSpec.billingCycle === BillingCycle.MONTHLY) {
			price = planSpec.priceExtraUnit;
		} else {
			price = planSpec.priceExtraUnit / 12;
		}
	}

	return Math.round(price * 100) / 100;
}

export function getPlanFromPlanID(
	config: ClientOrServerConfigWithPayment,
	planID: string | null | undefined,
): Plan {
	if (planID) {
		for (const planSpec of AVAILABLE_PLANS_SPEC) {
			// First check current plan IDs
			const plan = getPlanFromSubscriptionTierAndBillingCycle(
				config,
				planSpec.tier,
				planSpec.billingCycle,
			);

			if (plan.stripePlanID === planID || plan.paypalPlanID === planID) {
				return plan;
			}

			// As a fallback, check old plan IDs
			const oldPlanIDs = config.payment.oldTiers[planSpec.tier][planSpec.billingCycle];

			if (oldPlanIDs.includes(planID)) {
				return getPlanFromPlanSpec(config, planSpec);
			}
		}

		// If not found, see if it's an enterprise plan
		for (const enterprisePlan of config.payment.enterprisePlans) {
			if (planID === enterprisePlan.stripePlanID) {
				const plan = getPlanFromSubscriptionTierAndBillingCycle(
					config,
					SubscriptionTier.ENTERPRISE,
					enterprisePlan.billingCycle,
				);

				// Copy object to not modify `AVAILABLE_PLANS_SPEC`
				const newPlan = {
					...plan,
					price: enterprisePlan.price,
				};

				return newPlan;
			}
		}
	}

	// If not found anything, return first plan
	return getPlanFromPlanSpec(config, AVAILABLE_PLANS_SPEC[0]);
}

export function getSubscriptionTierFromPlanID(
	config: ClientOrServerConfigWithPayment,
	planID: string,
): ValueOf<typeof SubscriptionTier> {
	const plan = getPlanFromPlanID(config, planID);

	return plan.tier;
}

export function getSubscriptionTierAndBillingCycleKeyFromPlanID(
	config: ClientOrServerConfigWithPayment,
	planID: string,
): string {
	const plan = getPlanFromPlanID(config, planID);

	return getSubscriptionTierAndBillingCycleKey(plan);
}

export function getSubscriptionTierAndBillingCycleKey(planSpec: PlanSpec): string {
	return `${planSpec.tier}:${planSpec.billingCycle}`;
}

export function getSubscriptionTierLabel(
	subscriptionTier: ValueOf<typeof SubscriptionTier>,
): string {
	switch (subscriptionTier) {
		case SubscriptionTier.TEAM:
			return 'Team';
		case SubscriptionTier.ENTERPRISE:
			return 'Enterprise';
		case SubscriptionTier.SITE_MAKER:
			return 'Premium & Site';
		case SubscriptionTier.PRO:
		default:
			return 'Premium';
	}
}

export function getSubscriptionTierDescription(
	subscriptionTier: ValueOf<typeof SubscriptionTier>,
): string {
	switch (subscriptionTier) {
		case SubscriptionTier.TEAM:
			return 'Better collaboration for your team';
		case SubscriptionTier.SITE_MAKER:
			return 'Promote your work by creating a website';
		case SubscriptionTier.PRO:
		default:
			return 'Unlock your full creative potential';
	}
}

export function getPlanFeatures(planSpec: PlanSpec) {
	switch (planSpec.tier) {
		case SubscriptionTier.TEAM: {
			const priceExtraUnit = getPlanMonthlyExtraUnitPrice(planSpec);

			return [
				{ label: '10 first users included' },
				priceExtraUnit
					? {
							label: `${formatCurrency(priceExtraUnit)} / month per extra user`,
					  }
					: null,
				{ label: 'Admin roles' },
				{ label: 'Team Customization' },
				{ label: 'Present Work to Clients' },
				{ label: 'Comments' },
				{ label: 'Better Collaboration' },
				{ label: 'Figma Integration', soon: true },
			].filter(Boolean);
		}
		case SubscriptionTier.SITE_MAKER:
			return [
				{ label: 'Create your site in minutes' },
				{ label: 'Made for creatives' },
				{ label: 'Exclusive Templates' },
				{ label: 'All Premium features' },
				{ label: 'Connect your domain' },
				{ label: 'Access to our API', soon: true },
				{ label: 'Priority Support' },
				{ label: 'Embed your feed anywhere' },
			];
		case SubscriptionTier.ENTERPRISE:
			return [
				{ label: 'Unlimited Users' },
				{ label: 'SSO Login' },
				{ label: 'Server Customization' },
				{ label: 'Admin roles manager' },
				{ label: 'Internal Comments' },
				{ label: 'Internal Moderator' },
				{ label: 'Your Company Branding' },
				{ label: 'Domain Integration' },
			];
		case SubscriptionTier.PRO:
		default:
			return [
				{ label: 'Save unlimited images' },
				{ label: 'Create unlimited boards' },
				{ label: 'Personal support from our team' },
				{ label: 'Figma Plugin', soon: true },
				{ label: 'Early access to new features' },
				{ label: 'Get access images in full resolution' },
				{ label: 'Right click save from Desktop' },
				{ label: 'Cancel whenever' },
			];
	}
}

export function getSubscriptionTypeFromTier(
	tier: ValueOf<typeof SubscriptionTier>,
): ValueOf<typeof SubscriptionType> {
	return TIER_TO_TYPE_MAP[tier];
}

export function getSubscriptionTypeFromPlanID(
	config: ClientOrServerConfigWithPayment,
	planID: string,
): ValueOf<typeof SubscriptionType> {
	const plan = getPlanFromPlanID(config, planID);

	return getSubscriptionTypeFromTier(plan.tier);
}
