import { plural } from '@pkgs/shared-client/helpers/format';
import IconAddSVG from '@pkgs/shared-client/img/icon-add-inlined.svg';
import IconCheckSVG from '@pkgs/shared-client/img/icon-check-inlined.svg';
import { type GridColumnsConfigButtonSize } from '@pkgs/shared/constants';
import clsx from 'clsx';
import React from 'react';
import SVIconButton from './SVIconButton';

const SIZE_CLASSES = [
	'w-[44px] h-[44px]',
	'w-[40px] h-[40px]',
	'w-[36px] h-[36px]',
	'w-[32px] h-[32px]',
];

const ICON_SIZE_CLASSES = [
	'w-[17px] h-[17px] min-w-[17px] min-h-[17px]',
	'w-[15px] h-[15px] min-w-[15px] min-h-[15px]',
	'w-[13px] h-[13px] min-w-[13px] min-h-[13px]',
	'w-[11px] h-[11px] min-w-[11px] min-h-[11px]',
];

type Props = {
	itemsCount?: number;
	buttonSize?: GridColumnsConfigButtonSize;
	isSaved: boolean;
	isLoading: boolean;
	onClick: (event: React.UIEvent) => void;
};

// `buttonSize` -> 0 (regular) to 3 (small)
const SVGridItemSaveButton = React.forwardRef<HTMLDivElement, Props>(
	({ itemsCount = 0, buttonSize = 0, isSaved, isLoading, onClick }, forwardedRef) => {
		return (
			<div
				ref={forwardedRef}
				className="duration-over transition-opacity ease-out hover:opacity-80"
			>
				<SVIconButton
					className={clsx(
						SIZE_CLASSES[buttonSize],
						isSaved
							? 'bg-white text-black hover:text-black'
							: 'bg-black text-white hover:text-white',
					)}
					iconClassName={clsx(ICON_SIZE_CLASSES[buttonSize], isSaved && 'mt-[5%]')}
					src={isSaved ? IconCheckSVG : IconAddSVG}
					isLoading={isLoading}
					onClick={onClick}
					title={isSaved ? 'Saved' : plural(itemsCount, 'Save')}
				/>
			</div>
		);
	},
);

export default SVGridItemSaveButton;
